import { useState } from "react";
import { cloneDeep } from "lodash";
import { App } from "antd";
const useCourseDetails = (defaultCourses = []) => {
    const { message } = App.useApp();
    const [courses, setCourses] = useState(defaultCourses.length > 0 ? cloneDeep(defaultCourses) : []);
    console.table("courses",courses);
    const filter = (inputValue, path) => path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    const onChange = (course, selectedOptions) => {
        if (course != undefined && selectedOptions != undefined) {
            const selectedCourseId = course[0];
            //  if already exists in the courses array
            const findIndex = courses.findIndex(c => c.courseId === selectedCourseId);
            if (findIndex === -1) {
                let courseEnum = {
                    courseId: selectedCourseId,
                    courseName: selectedOptions[0].label,
                    learningMode: { title: "", value: "" },
                    batch: { title: "", value: "" },
                    description: selectedOptions[0].label,
                    extraOptions: {
                        needToEnroll: true
                    }
                }
                //  Six Sigma Courses
                if ([15, 14, 13, 12].includes(selectedCourseId)) {
                    if (selectedOptions.length > 1) {
                        courseEnum.description = `${courseEnum.description} / ${selectedOptions[1].label}`;
                        courseEnum.learningMode.title = selectedOptions[1].label;
                        courseEnum.learningMode.value = selectedOptions[1].value;
                    }
                    if (selectedOptions.length > 2) {
                        courseEnum.description = `${courseEnum.description} / ${selectedOptions[2].label}`;
                        courseEnum.batch.title = selectedOptions[2].label;
                        courseEnum.batch.value = selectedOptions[2].value;
                    }
                    if (selectedOptions.length > 3) {
                        courseEnum.extraOptions.examOnly = selectedOptions[3].value;
                    }
                }
                // PMP Course
                if (selectedCourseId === 10) {
                    courseEnum.description = `${courseEnum.description} / ${selectedOptions[1].label} / ${selectedOptions[2].label}`;
                    courseEnum.learningMode.title = selectedOptions[1].label;
                    courseEnum.learningMode.value = selectedOptions[1].value;
                    if (selectedOptions.length === 3) { courseEnum.extraOptions.examOnly = selectedOptions[2].value }
                    if (selectedOptions.length > 3) {
                        courseEnum.description = `${courseEnum.description} / ${selectedOptions[3].label}`;
                        courseEnum.batch.title = selectedOptions[2].label;
                        courseEnum.batch.value = selectedOptions[2].value;
                        courseEnum.extraOptions.examOnly = selectedOptions[3].value;
                    }
                }
                // ISO Course
                if (selectedCourseId === 69) {
                    courseEnum.description = `${courseEnum.description} / ${selectedOptions[1].label}`;
                    courseEnum.learningMode.title = "E-Learning"
                    courseEnum.learningMode.value = 1;
                    if (selectedOptions.length > 2) {
                        courseEnum.description = `${courseEnum.description} / ${selectedOptions[2].label}`;
                        if (selectedOptions[1].value === "irqa-certificate") {
                            courseEnum.extraOptions.needToEnroll = false;
                        }

                        // courseEnum.extraOptions.examOnly = selectedOptions[2].label;
                    }
                }
                // Other Courses
                if ([75, 87, 97, 98, 80, 99, 8].includes(selectedCourseId)) {
                    courseEnum.description = `${courseEnum.description} / ${selectedOptions[1].label}`;
                    courseEnum.learningMode.title = selectedOptions[1].label;
                    courseEnum.learningMode.value = selectedOptions[1].value;
                }
                const currentCourses = cloneDeep(courses);
                currentCourses.push(courseEnum);
                setCourses(cloneDeep(currentCourses));
            } else {
                message.open({ type: "error", content: "Course Already Exists!" });
            }
        }
    };

    const getUniqueCourses = () => {
        const temp_options = [];
        options.map((item) => {
            const found = courses.find((result) => result.courseId === item.value);
            if (!found) { temp_options.push(item) };
        })
        return cloneDeep(temp_options)
    }

    const removeCourse = (index) => {
        const currentCourses = cloneDeep(courses);
        currentCourses.splice(index, 1);
        setCourses(cloneDeep(currentCourses));
    }
    return {
        filter,
        onChange,
        courses,
        removeCourse,
        getUniqueCourses
    }
}
export default useCourseDetails;




const general_options = [{ value: 1, label: 'E-Learning' }, { value: 3, label: 'Virtual' }]

const six_sigma_courses = [
    {
        value: 1,
        label: 'E-Learning',
        children: [{ value: true, label: "Only Exam" }, { value: false, label: "Exam & Videos" },]
    },
    {
        value: 3,
        label: 'Virtual',
        children: [
            { value: "elite", label: 'Elite Batch' },
            { value: "standard", label: 'Standard Batch' },
        ]
    },
]

const iso_course_options = [
    {
        value: "imc-certificate",
        label: "IMC Certification",
    },
    {
        value: "irqa-certificate",
        label: "IRCA Certification",
        children: [
            { value: "ISO 9001:2015 Lead Auditor", label: "ISO 9001:2015 Lead Auditor" },
            { value: "ISO 45001:2018", label: "ISO 45001:2018" },
            { value: "ISO 14001:2015", label: "ISO 14001:2015" },
            { value: "ISO 22000:2018", label: "ISO 22000:2018" },
            { value: "ISO 27001:2022", label: "ISO 27001:2022" },
            { value: "ISO 13485:2026", label: "ISO 13485:2026" },
        ]
    },
    {
        value: "both-certificate",
        label: "ISO IMC & ISO IRCA",
        children: [
            { value: "ISO 9001:2015 Lead Auditor", label: "ISO 9001:2015 Lead Auditor" },
            { value: "ISO 45001:2018", label: "ISO 45001:2018" },
            { value: "ISO 14001:2015", label: "ISO 14001:2015" },
            { value: "ISO 22000:2018", label: "ISO 22000:2018" },
            { value: "ISO 27001:2022", label: "ISO 27001:2022" },
            { value: "ISO 13485:2026", label: "ISO 13485:2026" },
        ]
    },
]

const pmp_course_options = [
    {
        value: 1,
        label: 'E-Learning',
        children: [{ value: true, label: "With Exam" }, { value: false, label: "Without Exam" },]
    },
    {
        value: 3,
        label: 'Virtual',
        children: [
            { value: "1-Batch", label: "1 Batch", children: [{ value: true, label: "With Exam" }, { value: false, label: "Without Exam" },] },
            { value: "2 Batch", label: "2 Batch", children: [{ value: true, label: "With Exam" }, { value: false, label: "Without Exam" },] },
            { value: "unlimited", label: "Unlimited", children: [{ value: "exam", label: "With Exam" }, { value: "no-exam", label: "Without Exam" },] },
        ]
    },
]

const options = [
    {
        value: 15,
        label: 'Lean Six Sigma Yellow Belt',
        children: [{ value: 1, label: 'E-Learning' }]
    },
    {
        value: 14,
        label: 'Certified Lean Six Sigma Green Belt',
        children: six_sigma_courses
    },
    {
        value: 13,
        label: 'Certified Lean Six Sigma Black Belt',
        children: six_sigma_courses
    },
    {
        value: 12,
        label: 'Certified Lean Six Sigma Master Black Belt',
        children: [
            { value: 1, label: 'E-Learning' },
            { value: 3, label: 'Virtual' },
        ]
    },
    {
        value: 10,
        label: 'PMP Certification Training',
        children: pmp_course_options
    },
    {
        value: 75,
        label: "CLP (Certified Lean Practitioner)",
        children: [{ value: 1, label: 'E-Learning' }]
    },
    {
        value: 7,
        label: "Lean Manufacturing Certification",
        children: [{ value: 1, label: 'E-Learning' }]
    },
    {
        value: 69,
        label: "ISO 9001:2015 Lead Auditor Course",
        children: iso_course_options
    },
    {
        value: 87,
        label: "Certified Supply Chain Foundation",
        children: [{ value: 1, label: 'E-Learning' }]
    },
    {
        value: 97,
        label: "Certified Supply Chain Specialist",
        children: general_options
    },
    {
        value: 98,
        label: "Certified Supply Chain Expert",
        children: general_options
    },
    {
        value: 80,
        label: "Certified Human Resources Manager (CHRM)",
        children: [{ value: 1, label: 'E-Learning' }]
    },
    {
        value: 8,
        label: "Total Quality Management Certification",
        children: [{ value: 8, label: 'E-Learning' }]
    },
    {
        value: 99,
        label: "Certified Train the Trainer Course",
        children: [{ value: 3, label: 'Virtual' }]
    },
    {
        value: 29,
        label: "ITIL® 4 Foundation Certification Training",
        children: general_options
    },
    {
        value: 74,
        label: "Certified Professional of Advanced Quality Management of Managerial Work Process",
        children: [{ value: 1, label: 'E-Learning' }]
    },
]

